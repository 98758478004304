import React from 'react'
import { Container, Row, Col } from 'reactstrap';
import { Fade } from 'react-reveal';
import { Link, useParams } from "react-router-dom";

import icon from '../assets/images/icon.svg'
import iconCleaner from '../assets/icons/cleaner.svg'
import iconSecurity from '../assets/icons/security.svg'
import iconWifi from '../assets/icons/wifi.svg'
import iconKitchen from '../assets/icons/kitchen.svg'
import iconPrivet from '../assets/icons/privet.svg'
import iconWash from '../assets/icons/wash.svg'
import iconBed from '../assets/icons/bed.svg'
import iconCoins from '../assets/icons/coins.svg'
import iconGarden from '../assets/icons/garden.svg'

import offer from '../services/offer'
import tc from '../services/langs';

const icons = [iconCleaner, iconSecurity, iconWifi, iconCoins, iconKitchen, iconBed, iconGarden, iconWash]

export default function Offer(props) {
    
    let { lang } = useParams()
    lang = lang || "pt"

    return (
        <Container className={`${props.className} bg-white`} fluid>
            <Container id="offer">
                <Fade up>
                    <img src={icon} className="icon mt-5 mb-5" alt="Spatium Icon" />
                </Fade>
                <Fade down><h2 className="pb-5">{tc("What_we_have_to_offer", lang)}</h2></Fade>
                <Row>
                    {
                    offer
                    .map((a, i) => <Col key={`ofer_${i}`} className="col-md-3 col-12">
                                        <Fade up><img src={icons[i]} className="mt-5 mb-5" alt="Spatium Icon" style={{width: "80px", height: "80px"}} /></Fade>
                                        <Fade down><h4>{a['title_'+lang]}</h4></Fade>
                                        <Fade up><p>{a['description_'+lang]}</p></Fade>
                                    </Col>
                    )
                    }                    
                </Row>
            </Container>
        </Container>
    )
}
