import React from 'react'
import { Container, Row, Col } from 'reactstrap';
import { Fade } from 'react-reveal';
import { Link, useParams } from "react-router-dom";

//import icon from '../assets/images/icon.svg';
import logo from '../assets/images/logo.svg';
import logoDark from '../assets/images/logoDark.svg';
import iconInstagram from '../assets/icons/instagram.svg';
import iconFacebook from '../assets/icons/facebook.svg';
import iconTwitter from '../assets/icons/twitter.svg';
import iconInstagramLight from '../assets/icons/instagramLight.svg';
import iconFacebookLight from '../assets/icons/facebookLight.svg';
import iconTwitterLight from '../assets/icons/twitterLight.svg';
import tc from '../services/langs';

export default function Footer(props) {

    let { lang } = useParams()
    lang = lang || "pt"

    return (
        <Container id="footer" className={props.className} fluid>
            <Container className="pb-1">
                <Row>
                    <Col sm="6">
                        <Row>
                            <Link to={'/#home'}>
                            {
                                props.light
                                ? <Fade up><img className="logo-footer" src={logo} alt="Spatium" /></Fade> 
                                : <Fade up><img className="logo-footer" src={logoDark} alt="Spatium" /></Fade> 
                            }
                            </Link>
                        </Row>
                        <Row className="mt-4">
                            <Fade down><small>© Spatium 2021 - {tc("All_rights_reserved", lang)}</small></Fade>
                        </Row>
                    </Col>
                    <Col sm="6" className="social-icons text-right align-middle pt-4">
                        {
                        props.light
                        ? <Fade down>
                            <a href={props.contacts?.instagram}><img src={iconInstagramLight} target="_blank" alt="instagram" className="mr-3" /></a>
                            <a href={props.contacts?.facebook}><img src={iconFacebookLight} target="_blank" alt="facebook" className="mr-3" /></a>
                            <a href={props.contacts?.twitter}><img src={iconTwitterLight} target="_blank" alt="twitter" className="mr-3" /></a>
                            </Fade>
                        : <Fade down>
                            <a href={props.contacts?.instagram}><img src={iconInstagram} target="_blank" alt="instagram" className="mr-3" /></a>
                            <a href={props.contacts?.facebook}><img src={iconFacebook} target="_blank" alt="facebook" className="mr-3" /></a>
                            <a href={props.contacts?.twitter}><img src={iconTwitter} target="_blank" alt="twitter" className="mr-3" /></a>
                          </Fade>
                        }
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}