import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Alert } from 'reactstrap';
import { Fade, Slide } from 'react-reveal';
import { Link, useParams } from "react-router-dom";

import Footer from '../components/Footer'

import api from '../services/api'

import logoDark from '../assets/images/logoDark.svg';

export default function(props) {

  let { lang } = useParams()
  lang = lang || "pt"

  const [logged, setLogged]     = useState(false)
  const [model, setModel]       = useState("")
  const [data, setData]         = useState({user: {email: localStorage.getItem("email") || "", password: ""}, banners: [], rooms: [], contacts: {}})
  const [indexes, setIndexes]   = useState({banners: -1, rooms: -1})

  useEffect(() => {
    window.scrollTo(0, 0)

    getData()
  }, []);

  useEffect(() => {
    let token = localStorage.getItem("spatium@token")
    setLogged(token ? true : false)

  }, [data]);

  const getData = function() {
    api("/api/feed", "GET", {}, (response)=> setData(Object.assign({}, data, response.data)))

    //console.log(data) //DEBUG
  }
  
  const handleLogin = function() {

    const callback = function(response) {
      localStorage.setItem("spatium@token", response.data.jwt)
      localStorage.setItem("email", data.user.email)
      setLogged(true)
    }

    !data.user?.email || !data.user?.password
      ? alert("Por favor preencha seu e-mail e senha.")
      : api("/api/users/login", "POST", data.user, callback)
  }

  const handleLogout = function() {
    localStorage.removeItem("spatium@token")
    setLogged(false)
  }

  const handleModel = function(model, i = 0) {

    if(["rooms","banners"].includes(model)) {
      let _indexes = Object.assign({}, indexes)

      _indexes[model] = i

      setIndexes(_indexes)
    }

    setModel(model)
  }

  const handleData = function(event, model, field) {
    let _data = Object.assign({}, data)

    let i = indexes[model]

    i === undefined
      ? _data[model][field]    = event.target.value
      : _data[model][i][field] = event.target.value

    setData(_data)
  }

  const handleDataSave = function(response = false) {
    let _data = ["rooms", "banners"].includes(model)
                ? data[model][indexes[model]]
                : data[model]

    api(`/api/${model}`, "PUT", _data, ()=> { alert("Dados salvos com sucesso."); setModel("") })
  }

  const handleCancel = function() {
    setModel("")
  }

  return (
        <div id="admin" className={logged ? "logged" : "noLogged"}>
          <Container className="mt-4">
            <Fade down>
              <Link to={"/"}><img src={logoDark} className="logo mt-4" alt="Spatium" /></Link>
            </Fade>
          </Container>
          {
          !data.rooms.length || !data.banners.length 
            ? <Container><Row className="mt-5 mb-5">Loading data...</Row></Container>
            : <Container className="pb-5">
              
              <Row className="mt-5">
                <Fade down><h3>{props.title}</h3></Fade>
              </Row>

              {
                !logged
                ? <Row id="form_login" className="mt-4">
                    <Col className="radius border p-5 text-center col-md-8 offset-md-2">
                      <h4 className="text-center mb-4">Admin</h4>
                      <p>
                        <input 
                          type="text" 
                          placeholder="Login" 
                          value={data.user?.email} 
                          onChange={(e)=>handleData(e, "user", "email")} 
                        />
                      </p>
                      <p>
                        <input 
                          type="password" 
                          placeholder="Password" 
                          value={data.user?.password} 
                          onChange={(e)=>handleData(e, "user", "password")} 
                        />
                      </p>
                      <button className="radius p-2 pl-5 pr-5 mt-4" onClick={handleLogin}>Login</button>
                    </Col>
                  </Row>
                : <Row id="forms" className="mt-4">
                      <Col>
                        <Fade up>
                          <h5 className="mt-5 pb-2">Banners</h5>
                        </Fade>
                        <Fade down>
                          {
                          data
                          .banners
                          .map((banner, i)=> <p>
                                <a href="#" key={"btn_banner"+i} onClick={()=>handleModel("banners", i)}>{banner.type == "R" ? "Residence" : "Tourism"}</a>
                            </p>
                          )
                          }
                        </Fade>
                        <Fade up>
                          <h5 className="mt-5 pb-2">Rooms</h5>
                        </Fade>
                        <Fade down>
                          {
                            data
                            .rooms
                            .map((room, i)=> <p key={"btn_room"+i}><a href="#" className={indexes.rooms == i ? "selected" : ""} onClick={()=>handleModel("rooms", i)}>{room.type == "R" ? "Residence" : "Tourism"} - {room.title_pt || room.title_en}</a></p>)
                          }
                        </Fade>
                        <Fade up>
                          <h5 className="mt-5 pb-2">General</h5>
                        </Fade>
                        <Fade down>
                          <p><a href="#" onClick={()=>handleModel("contacts")}>Contacts</a></p>
                        </Fade>
                        <Fade down>
                          <button className="radius p-2 pl-5 pr-5 mt-4 mr-1" onClick={handleLogout}>Logout</button>
                        </Fade>
                      </Col>
                      <Col className="radius border p-5 col-9 forms">
                        <h4 className="mb-4">
                          Data Editor
                          { 
                            ["banners", "rooms"].includes(model)
                            ? model ? " (" + model + " - " + (data[model][indexes[model]].type == "R" ? "residence" : "tourism") + ")" : ""
                            : ""
                          }
                        </h4>

                        {
                        model == "banners" &&
                        <section>
                          <small>Title PT</small>
                          <p ><input className="w-100" type="text" placeholder="Title PT" value={data.banners[indexes.banners].title_pt} onChange={(e)=>handleData(e, "banners", "title_pt")} /></p>
                          <small>Subtitle PT</small>
                          <p ><input className="w-100" type="text" placeholder="Title PT" value={data.banners[indexes.banners].title2_pt} onChange={(e)=>handleData(e, "banners", "title2_pt")} /></p>
                          <small>Title EN</small>
                          <p><input className="w-100" type="text" placeholder="Title EN" value={data.banners[indexes.banners].title_en} onChange={(e)=>handleData(e, "banners", "title_en")} /></p>
                          <small>Subtitle EN</small>
                          <p ><input className="w-100" type="text" placeholder="Title PT" value={data.banners[indexes.banners].title2_en} onChange={(e)=>handleData(e, "banners", "title2_en")} /></p>
                        </section>
                        }

                        {
                        model == "rooms" &&
                        <section>
                          <h4>Data Portuguese</h4>
                          <small>Title PT</small>
                          <p><input className="w-100" type="text" value={data.rooms[indexes.rooms]?.title_pt} onChange={(e)=>handleData(e, "rooms", "title_pt")} /></p>
                          <small>Includes PT</small>
                          <p><input className="w-100" type="text" value={data.rooms[indexes.rooms]?.includes_pt} onChange={(e)=>handleData(e, "rooms", "includes_pt")} /></p>
                          <small>Description PT</small>
                          <p><textarea className="w-100" type="text" onChange={(e)=>handleData(e, "rooms", "description_pt")} value={data.rooms[indexes.rooms]?.description_pt}></textarea></p>
                          <small>Features PT</small>
                          <p><textarea className="w-100" rows="5" onChange={(e)=>handleData(e, "rooms", "features_pt")} value={data.rooms[indexes.rooms]?.features_pt}></textarea></p>
                          <small>Furniture PT</small>
                          <p><textarea className="w-100" rows="7" onChange={(e)=>handleData(e, "rooms", "furniture_pt")} value={data.rooms[indexes.rooms]?.furniture_pt}></textarea></p>

                          <h4>Data English</h4>
                          <small>Title EN</small>
                          <p><input className="w-100" type="text" value={data.rooms[indexes.rooms]?.title_en} onChange={(e)=>handleData(e, "rooms", "title_en")} /></p>
                          <small>Includes EN</small>
                          <p><input className="w-100" type="text" value={data.rooms[indexes.rooms]?.includes_en} onChange={(e)=>handleData(e, "rooms", "includes_en")} /></p>
                          <small>Description EN</small>
                          <p><textarea className="w-100" type="text" onChange={(e)=>handleData(e, "rooms", "description_en")} value={data.rooms[indexes.rooms]?.description_en}></textarea></p>
                          <small>Features EN</small>
                          <p><textarea className="w-100" rows="5" onChange={(e)=>handleData(e, "rooms", "features_en")} value={data.rooms[indexes.rooms]?.features_en}></textarea></p>
                          <small>Furniture EN</small>
                          <p><textarea className="w-100" rows="7" onChange={(e)=>handleData(e, "rooms", "furniture_en")} value={data.rooms[indexes.rooms]?.furniture_en}></textarea></p>


                          <h4>Price</h4>
                          <small>€</small>
                          <p><input className="w-100" type="number" placeholder="€0,00" value={data.rooms[indexes.rooms]?.price} onChange={(e)=>handleData(e, "rooms", "price")} /></p>
                          <h4 className="mt-4">Images</h4>

                          <small>Instagram Link</small>
                          <p><input className="w-100" type="text" placeholder="https://instagram.com/" value={data.rooms[indexes.rooms]?.instagram} onChange={(e)=>handleData(e, "rooms", "instagram")} /></p>
                        </section>
                        }

                        {
                        model == "contacts" &&
                        <section>
                          <small>Email</small>
                          <p><input className="w-100" type="text" placeholder="" value={data.contacts?.email} onChange={(e)=>handleData(e, "contacts", "email")} /></p>
                          <small>Phone</small>
                          <p><input className="w-100" type="text" placeholder="" value={data.contacts?.phone} onChange={(e)=>handleData(e, "contacts", "phone")} /></p>
                          <small>Address</small>
                          <p><input className="w-100" type="text" placeholder="" value={data.contacts?.address} onChange={(e)=>handleData(e, "contacts", "address")} /></p>
                          <h4 className="mt-4">Social Networks</h4>
                          <small>Twitter</small>
                          <p><input className="w-100" type="text" placeholder="" value={data.contacts?.twitter} onChange={(e)=>handleData(e, "contacts", "twitter")} /></p>
                          <small>Instagram</small>
                          <p><input className="w-100" type="text" placeholder="" value={data.contacts?.instagram} onChange={(e)=>handleData(e, "contacts", "instagram")} /></p>
                          <small>Facebook</small>
                          <p><input className="w-100" type="text" placeholder="" value={data.contacts?.facebook} onChange={(e)=>handleData(e, "contacts", "facebook")} /></p>
                        </section>
                        }

                        {
                          model
                          ? <p className="mt-2">
                              <button className="radius p-2 pl-5 pr-5 mt-4 mr-1" onClick={handleDataSave}>Save</button>
                              <button className="radius p-2 pl-5 pr-5 mt-4 btn-cancel" onClick={handleCancel}>Cancel</button>
                            </p>
                          : <span>Select an item in the side menu</span>
                        }
                        
                      </Col>
                  </Row>
              } 
            </Container>
          }
          <Footer className="pt-5 pb-5 bg-dark" light={true} />
        </div>
    )
} 