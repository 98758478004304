const axios = require('axios');

const url = "https://spatium.beejs.org:8443"

function headers() {
    return { 
        'Content-Type': 'application/json', 
        Authorization: `Bearer ${localStorage.getItem('spatium@token')}` 
       }
}

export default function api(route, method = "GET", data = {}, callback) {
    
    route = url + route

    axios[method.toLocaleLowerCase()](route, data, {headers: headers()})
    .then(function (response) {
        // handle success
        if(callback)
            callback(response.data)
    })
    .catch(function (error) {
        // handle errors

        if(error.response) {
            error.response.status == 403
                ? alert("Login ou senha inválidos")
                : alert("ERROR ao comunicar com a API")
        }
    })
    .then(function () {
        // always executed
    });
}