import React, { useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Fade } from 'react-reveal';
import { Link, useParams } from "react-router-dom";
import { SRLWrapper } from "simple-react-lightbox";

import Footer from '../components/Footer'

import logoDark from '../assets/images/logoDark.svg';
import tc from '../services/langs';

export default function(props = {}) {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let { lang, id } = useParams()
  lang = lang || "pt"
  id   = id || 0
  
  let room = props.rooms.find(a => a.id_room == id)

  let images = room.id_room < 3 
                ? [2,3] 
                : [2,3,4]

  return (
        <>
          <Container className="mt-4">
            <Row>
              <Col>
                <Fade down>
                  <Link to={"/"+lang}><img src={logoDark} className="logo mt-4" alt="Spatium" /></Link>
                </Fade>
              </Col>
              <Col className="pt-5 text-right">
                <a className="click" onClick={()=> window.history.back() }>{tc("Back", lang)}</a>
              </Col>
            </Row>
          </Container>
          <Container id="room" className="pb-5">
            <SRLWrapper>
              <Row>
                <Col sm="7" className="mt-5">
                  <Fade>
                    <img className="thumb radius click" src={`/assets/images/rooms/${room.id_room}/1.JPG`} />
                  </Fade>
                </Col>
                <Col sm="5" className="mt-5">
                {
                  images.map((thumb, index) => <Row className={index > 0 ? "mt-3" : ""}>
                    <Fade>
                      <img style={{height: images.length == 2 ? "250px" : "160px"}} className="thumb-mini radius click" src={`/assets/images/rooms/${room.id_room}/${thumb}.JPG`} />
                    </Fade>
                  </Row>
                    )
                }
                </Col>
              </Row>
            </SRLWrapper>
            
            <Row className="mt-5">
              <Fade down><h3>{room['title_'+lang]}</h3></Fade>
            </Row>

            <Row className="mt-4">
              <Col>
                <Fade up>
                  <h5 className="mt-5 pb-2">{tc("Features", lang)}</h5>
                </Fade>
                <Fade down>
                  {(room[`features_${lang}`] || '').split("\n").map((p, i)=> <p key={`feature_${i}`}>{p}</p>)}
                </Fade>
              </Col>
              <Col>
                <Fade up>
                  <h5 className="mt-5 pb-2">{tc("Furniture", lang)}</h5>
                </Fade>
                <Fade down>
                  {(room[`furniture_${lang}`] || '').split("\n").map((p, i)=> <p key={`furniture_${i}`}>{p}</p>)}               
                </Fade>
              </Col>
              <Col className="radius border p-5 text-center">
                {
                room.price == 0 
                ? <div><br /><br /><br /></div>
                : <div>
                    <h1 className="text-center mb-0">{room.price}€</h1>
                    <small>{tc("Per_month", lang)}</small>
                    <p className="mt-2">{room[`includes_${lang}`]}</p>
                  </div>
                }
                <button className="radius p-3 pl-5 pr-5 mt-4" onClick={()=>window.location.href="mailto:geral@spatiumresidence.com"}>{tc("Contact", lang)}</button>
              </Col>
            </Row>
          </Container>
          <Footer className="pt-5 pb-5 bg-dark" light={true} />
        </>
    )
} 