import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Fade, Slide } from 'react-reveal';
import { Link, useParams } from "react-router-dom";
import ReactCountryFlag from "react-country-flag";
import { SRLWrapper } from "simple-react-lightbox";

import Rooms from '../components/Rooms'
import Offer from '../components/Offer'
import Prices from '../components/Prices'
import Contact from '../components/Contact'
import Footer from '../components/Footer'

import tc from '../services/langs' 

import logo from '../assets/images/logo.svg';

export default function(props) {

  let { lang } = useParams()
  lang = lang || "pt"

  const _lang = lang.toLocaleLowerCase()

  return (
        <>
        {
          props.type == "tourism"
          ? <Container className="header background" fluid style={{backgroundImage: `url(/assets/images/bg-tourism.JPG)`}}>
              <Container className="pt-5 pb-5">
                <Row className="mb-5 pr-5">
                  <Col>
                    <Fade down>
                      <Link to={"/"+_lang}><img src={logo} className="logo mt-4" alt="Spatium" /></Link>
                    </Fade>
                  </Col>
                  <Col className="text-right">
                    <Link to="/pt/tourism"><ReactCountryFlag countryCode="PT" svg className="mr-1" /></Link> 
                    <Link to="/en/tourism"><ReactCountryFlag countryCode="GB" svg className="mr-1" /></Link> 
                    <Link to={"/"+_lang} className="text-white p-2">Residence</Link>
                  </Col>
                </Row>
                <Row>
                  <Col className="mt-4 pb-5">
                    <Fade down>
                      <h4 className="mt-5">{tc("Tourism", lang)}</h4>
                    </Fade>
                    <Fade up>
                      <h1 className="mt-1">{props.banners['title_'+lang] || ""}</h1>
                    </Fade>
                    <Fade down>
                      <h3 className="mt-5">{props.banners['title_'+lang] || ""}</h3>
                    </Fade>
                  </Col>
                </Row>
              </Container>
            </Container>
          : <Container id="home" className="header background" fluid style={{backgroundImage: `url(/assets/images/bg-default.JPG)`}}>
              <Container className="pt-5 pb-5">
                <Row className="mb-5">
                  <Col>
                    <Fade down>
                      <Link to={"/"+_lang}><img src={logo} className="logo mt-4" alt="Spatium" /></Link>
                    </Fade>
                  </Col>
                  <Col className="text-right">
                    <Link to="/pt"><ReactCountryFlag countryCode="PT" svg className="mr-1" style={{fontSize: '2em'}} /></Link> 
                    <Link to="/en"><ReactCountryFlag countryCode="GB" svg className="mr-1" style={{fontSize: '2em'}} /></Link> 
                    {
                    1==2 &&
                    <Link to={`/${_lang}/tourism`} className="text-white p-2">{tc("Tourism", lang)}</Link>
                    }
                  </Col>
                </Row>
                <Row>
                  <Col className="mt-4 pb-5">
                    <Fade up>
                      <h1 className="mt-5">{props.banners['title_'+lang] || ""}</h1>
                    </Fade>
                    <Fade down>
                      <h3 className="mt-5">{props.banners['title2_'+lang] || ""}</h3>
                    </Fade>
                  </Col>
                </Row>
              </Container>
            </Container>
        }
        <Container className="pt-5 pb-5">
          <SRLWrapper>
            <Row>
              <Col md="7" className="mt-5">
                <Fade>
                    <img className="thumb radius click" src={`/assets/images/photo-home-1.JPG`} />
                </Fade>
              </Col>
              <Col md="5" className="mt-5">
                <Fade>
                  <img className="thumb radius click" src={`/assets/images/photo-home-2.JPG`} />
                </Fade>
              </Col>
            </Row>
            <Row>
              <Col md="5"  className="mt-5">
                <Fade>
                  <img className="thumb radius click" src={`/assets/images/photo-home-3.JPG`} />
                </Fade>
              </Col>
              <Col md="7" className="mt-5">
                <Fade>
                  <img className="thumb radius click" src={`/assets/images/photo-home-4.JPG`} />
                </Fade>
              </Col>
            </Row>
          </SRLWrapper>
          </Container>
          <Rooms rooms={props.rooms} lang={lang} />
          <Offer lang={lang} className="mt-5 pt-5 pb-5" />
          { 
            1 == 2 && 
            <Prices className="mt-5 pt-5 pb-5" />
          }
          <Contact 
            {...props}
            className="pt-5 pb-5"
          />
          <div id="map" className="text-center bg-grey">
            <iframe 
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3043.5423466001953!2d-7.5124663846080635!3d40.28591537938061!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zNDDCsDE3JzA5LjMiTiA3wrAzMCczNy4wIlc!5e0!3m2!1spt-BR!2spt!4v1604451501568!5m2!1spt-BR!2spt" 
              width="100%" 
              height="300"
              >
            </iframe>
          </div>
          <Footer
            {...props}
            className="pt-5 pb-5 bg-white" 
          />
        </>
    )
} 