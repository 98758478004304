import React from 'react'
import { Container, Row, Col } from 'reactstrap';
import { Fade } from 'react-reveal';
import { Link, useParams } from "react-router-dom";

//import icon from '../assets/images/icon.svg';
import iconFlag from '../assets/icons/flag.svg';
import iconEmail from '../assets/icons/email.svg';
import iconPhone from '../assets/icons/phone.svg';
import tc from '../services/langs';

export default function Cotnacts(props) {

    let { lang } = useParams()
    lang = lang || "pt"

    return (
        <Container id="contact" className={`${props.className} bg-dark`}fluid>
            <Container className="pb-5">
                <Fade down><h2 className="pt-5 white">{tc("Get_in_touch_with_us", lang)}</h2></Fade>
                <Row>
                    <Col sm="5" className="mt-5">
                        <Fade up><h6><img src={iconFlag} alt="Address" className="mr-3" /> <small>{props.contacts.address}</small></h6></Fade>
                    </Col>
                    <Col sm="3" className="mt-5">
                        <Fade down><h6><img src={iconPhone} alt="Phone" className="mr-3" /> <small>{props.contacts.phone}</small></h6></Fade>
                    </Col>
                    <Col sm="4" className="mt-5">
                        <Fade up><h6><img src={iconEmail} alt="Email" className="mr-3" /> <small>{props.contacts.email}</small></h6></Fade>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}