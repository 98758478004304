const pt = {
    Tourism: "Turismo",
    Know_more: "Saiba mais",
    Per_month: "Por mês",
    From: "Desde",
    Back: "Voltar",
    Features: "CaracterÍsticas",
    Services: "Serviços",
    Contact: "Contactar",
    Secretary: "Secretária",
    Single_bed: "Cama individual",
    Closet: "Roupeiro",
    Our_room_selection: "A nossa seleção de quartos",
    What_we_have_to_offer: "O que temos a oferecer",
    Get_in_touch_with_us: "Entre em contato connosco",
    All_rights_reserved: "Todos os direitos reservados",
    Individual_kitchen_kit: "Kit cozinha individual",
    Double_kitchen_kit: "kit cozinha duplo",
    Single_bed_linen_kit: "Kit roupa de cama individual",
    Double_bedding_kit: "Kit roupa de cama duplo",
    Furniture: "Mobília",
    "Includes_internet_and_cleaning": "Inclui internet e limpeza",
    "Includes_internet": "Inclui internet",
}

const langs = {
    pt: pt,
    en: {},
    us: {}
}

export default function tc(word, lang = "pt") {
    return langs[lang.toLocaleLowerCase()][word] || word.replace(/_/g," ")
}