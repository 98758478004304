import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Fade } from 'react-reveal';
import { Link, useParams } from "react-router-dom";

import tc from '../services/langs';

import icon from '../assets/images/icon.svg';

export default function(props = {}) {

  let { lang, id } = useParams()
  lang = lang || "pt"

  return (
        <Container className="bg-grey mt-5" fluid>
          <Container className="pt-4 pb-5">
          <Fade up>
            <img src={icon} className="mt-5 mb-5" alt="Spatium Icon" />
          </Fade>
          <Fade down><h2 className="pb-5">{tc("Our_room_selection", lang)}</h2></Fade>
          {
            props
            .rooms
            .map((room, index)=> <Row className="mt-5 card-over" key={index}>
              <Col sm="12" className="card radius pt-5 pb-3" style={{backgroundImage: `url(/assets/images/rooms/${room.id_room}/1.JPG)`}}>
                <Row className="p-5">
                  <Col className="p-5 radius bg-white card-descriptions">
                    <Fade down>
                      <h4 className="pb-3">{room['title_'+lang]}</h4>
                    </Fade>
                    <Fade up>
                      <p className="pb-3">{room['description_'+lang]}</p>
                    </Fade>
                      <Row>
                        <Col sm="6">
                          {
                          room.price > 0 &&
                          <Fade down>
                            <p className="pb-3"><br />{tc("From", lang)} <strong>{room.price}€</strong> / <span>{(tc("Per_month", lang)).toLowerCase()}</span></p>
                          </Fade>
                          }
                        </Col>
                        <Col sm="6" className="text-right">
                          <Fade up>
                            <Link to={`/${lang}/room/${room.id_room}/${room.title_pt.replace(/ /g,"-").toLowerCase()}`}><button className="radius p-3 pl-5 pr-5">{tc("Know_more", lang)}</button></Link>
                          </Fade>
                        </Col>
                      </Row>
                  </Col>
                </Row>
              </Col>
              <Col sm="12" className="p-5 radius bg-white card-descriptions-phone">
                    <Fade down>
                      <h4 className="pb-3">{room['title_'+lang]}</h4>
                    </Fade>
                    <Fade up>
                      <p className="pb-3">{room['description_'+lang]}</p>
                    </Fade>
                      <Row>
                        {
                        room.price > 0 &&
                        <Col sm="6">
                          <Fade down>
                            <p className="pb-3"><br />{tc("From", lang)} <strong>{room.price}€</strong> / <span>{(tc("Per_month", lang)).toLowerCase()}</span></p>
                          </Fade>
                        </Col>
                        }
                        <Col sm="6" className="text-right">
                          <Fade up>
                            <Link to={`/${lang}/room/${room.id_room}/${room.title_pt.replace(/ /g,"-").toLowerCase()}`}><button className="radius p-3 pl-5 pr-5">{tc("Know_more", lang)}</button></Link>
                          </Fade>
                        </Col>
                      </Row>
              </Col>
            </Row>
            )
          }
        </Container>
      </Container>
    )
}