export default [
    {
        title_pt: "Limpeza",
        description_pt: "As zonas comuns são limpas diariamente.",
        title_en: "Cleaning",
        description_en: "The common areas are cleaned daily."
    },
    {
        title_pt: "Segurança",
        description_pt: "Para garantir a tua segurança, as portas abrem com código pessoal e existem câmaras nas áreas comuns.",
        title_en: "Security",
        description_en: "To ensure your safety, the doors open with a personal code and there are cameras in the common areas.",
    },
    {
        title_pt: "WIFI Rápido",
        description_pt: "Vais poder navegar na internet a alta velocidade, em toda a residência.",
        title_en: "Fast WIFI",
        description_en: "You will be able to surf the internet at high speed, all over the residence.",    
    },
    {
        title_pt: "Despesas fixas",
        description_pt: "As despesas de água, eletricidade e gás estão limitadas a um valor fixo, para evitar surpresas!",
        title_en: "Fixed expenses",
        description_en: "Water, electricity and gas costs are limited to a fixed amount, to avoid surprises!",
    },
    {
        title_pt: "Kitchenette",
        description_pt: "Todos os estúdios têm kitchenette equipada com frigorifico, fogão, forno e microondas.",
        title_en: "Kitchenette",
        description_en: "All studios have a kitchenette equipped with a refrigerator, stove, oven and microwave.",
    },
    {
        icon: "",
        title_pt: "Cama de Casal",
        description_pt: "Todos os estúdios estão equipados com camas de casal.",
        title_en: "Double Bed",
        description_en: "All studios are equipped with double bed."
    },
    {
        icon: "",
        title_pt: "Jardim",
        description_pt: "A residência possui um jardim luxuriante onde podes realizar eventos ou simplesmente relaxar.",
        title_en: "Garden",
        description_en: "The residence has a lush garden where you can hold events or just relax.",
    },
    {
        icon: "",
        title_pt: "Lavandaria",
        description_pt: "Podes lavar, secar e engomar a tua roupa, ou confiá-la à nossa equipa que a entregará lavada e engomada.",
        title_en: "Laundry",
        description_en: "You can trust your clothes to our cleaning team, who will deliver them washed and ironed.",
    }
]
