import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Home from './pages/Home'
import Room from './pages/Room'
import Admin from './pages/Admin'

import Loading from './components/Loading'

import api from './services/api'

import './styles/App.css';

export default function App() {

  const [data, setData] = useState({banners: [], rooms: [], contacts: {}})

  useEffect(() => {
    window.scrollTo(0, 0);

    if(!data.banners.length) 
      getData()

  }, [data]);

  const getData = function() {
    api("/api/feed", "GET", {}, (response)=> setData(Object.assign({}, data, response.data)))

    console.log(data)
  }

  return (
      !data.rooms.length
      ? <Loading />
      : <Router>
          <Switch>
            <Route exact path={"/admin"}>
              <Admin />
            </Route>
            <Route exact path={"/webmail"}>
              <iframe src="https://mail3.mailbox.pt/" width="100%" height="100%" frameBorder="0"></iframe>
            </Route>
            <Route exact path={["/","/:lang"]}>
              <Home 
                rooms={data.rooms.filter(a => a.type == "R")} 
                banners={data.banners.filter(a => a.type == "R")[0]}
                contacts={data.contacts}
                type={"residence"} 
              />
            </Route>
            <Route exact path={"/:lang/tourism"}>
              <Home 
                rooms={data.rooms.filter(a => a.type == "T")} 
                banners={data.banners.filter(a => a.type == "T")[0]} 
                contacts={data.contacts}
                type={"tourism"} 
              />
            </Route>
            <Route exact path={"/:lang/room/:id/:title"}>
              <Room rooms={data.rooms} />
            </Route>
          </Switch>
        </Router>
    )
}